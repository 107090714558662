import module from './module';
import moment from 'moment';
export default module.directive('dirTimeBarDisplay', [
  '$document',
  '$timeout',
  'sessionManagerService',
  'timeService',
  'TimeFormatService',
  'AuthenticationService',
  function ($document, $timeout, sessionManagerService, timeService, TimeFormatService, AuthenticationService) {
    var toRender = [];
    var timeout;
    var tableBodyWidth, columnLabelWidth;
    const CURRENT_USER = AuthenticationService.getCurrentUser();
    const loadAllJobs = CURRENT_USER.market_place_info ? CURRENT_USER.market_place_info.load_all_jobs : false;

    return {
      restrict: 'E',
      scope: {
        session: '=',
        display: '=',
        startDate: '=',
      },
      link: function (scope, element, attr) {
        toRender.push(setMinuteHashMark);
        var colorFill,
          sessionWidth,
          offSetDisplay,
          offsetStartTime,
          hashMinuteCount,
          opacity,
          backGround,
          zIndex = 2,
          cssClass,
          rowBodyWidth;

        function setElementAttribs() {
          if (sessionWidth < 1) {
            sessionWidth = 1;
          }
          element.css({
            width: sessionWidth + 'px',
            left: offSetDisplay + 'px',
            backgroundColor: colorFill,
            zIndex: zIndex,
            opacity: opacity,
            background: backGround,
          });
          if (!attr.id) {
            element.addClass('overlap-block');
          }
          if (cssClass) {
            element.addClass(cssClass);
          }
        }

        function setColor(session) {
          if (session.child == true) {
            if (session.association == false) {
              colorFill = '#dee5ea';
              zIndex = '0';
            } else if (loadAllJobs && session.pause_job) {
              zIndex = 10;
              colorFill = getColor('paused');
            } else {
              zIndex = 10;
              if (loadAllJobs) {
                setColorNew(session.state.name.toLowerCase());
              } else {
                switch (session.state.name.toLowerCase()) {
                  case 'completed':
                    colorFill = 'rgba(45, 57, 65, 0.3)';
                    break;
                  case 'open':
                    colorFill = 'rgba(245, 166, 35, 0.4)';
                    break;
                  case 'committed':
                    colorFill = '#7f5fa4';
                    opacity = '0.5';
                    break;
                  case 'active':
                    colorFill = '#38b393';
                    opacity = '0.5';
                    break;
                  case 'overlap':
                    backGround =
                      'repeating-linear-gradient(45deg, transparent, transparent 5px, rgba(255, 255, 255, 0.15) 5px, rgba(255, 255, 255, 0.15) 10px)';
                    colorFill = '#cc514c';
                    opacity = '0.5';
                    break;
                  case 'canceled':
                    colorFill = 'brown';
                    opacity = '0.5';
                    cssClass = 'timebar-canceled';
                    break;
                  case 'pending':
                    colorFill = '#4fafe0';
                    opacity = '0.5';
                    break;
                  case 'interest':
                    colorFill = 'teal';
                    opacity = '0.5';
                    break;
                  case 'waiting decision':
                    colorFill = 'orange';
                    opacity = '0.5';
                    break;
                }
              }
            }
          } else {
            if (loadAllJobs && session.pause_job) {
              colorFill = getColor('paused');
            } else {
              if (session.state.name.toLowerCase() === 'overlap') {
                backGround = buildOverlapColor(session);
                if (
                  session.firstSessionState.name.toLowerCase() === 'canceled' ||
                  session.secondSessionState.name.toLowerCase() === 'canceled'
                ) {
                  cssClass = 'timebar-canceled';
                }
              } else {
                if (session.state.name.toLowerCase() === 'canceled') {
                  cssClass = 'timebar-canceled';
                }
                colorFill = getColor(session.state.name.toLowerCase());
              }
            }
          }
          setElementAttribs();
        }

        function setColorNew(state) {
          switch (state) {
            case 'completed':
              colorFill = '#0059BD';
              break;
            case 'open':
              colorFill = '#0070C0';
              break;
            case 'committed':
              colorFill = '#47CD89';
              opacity = '0.5';
              break;
            case 'active':
              colorFill = '#079455';
              opacity = '0.5';
              break;
            case 'overlap':
              backGround =
                'repeating-linear-gradient(45deg, transparent, transparent 5px, rgba(255, 255, 255, 0.15) 5px, rgba(255, 255, 255, 0.15) 10px)';
              colorFill = '#101828';
              opacity = '0.5';
              break;
            case 'canceled':
              colorFill = '#BF0000';
              opacity = '0.5';
              cssClass = 'timebar-canceled';
              break;
            case 'pending':
              colorFill = '#DC6803';
              opacity = '0.5';
              break;
            case 'interest':
              colorFill = '#B4C5E2';
              opacity = '0.5';
              break;
            case 'waiting decision':
              colorFill = '#F79009';
              opacity = '0.5';
              break;
            case 'paused':
              colorFill = '#667085';
              break;
          }
        }

        function setAttribs() {
          tableBodyWidth = tableBodyWidth || $('#time-table-body').width();
          columnLabelWidth = columnLabelWidth || $('#time-table-label-column:visible').width();
          rowBodyWidth = tableBodyWidth - columnLabelWidth;
          var _hashMarks = (rowBodyWidth - 21) / hashMinuteCount;
          sessionWidth = _hashMarks * scope.session.duration;
          var _sessionStart = moment(scope.session.start);
          var _timeDiff = _sessionStart.diff(offsetStartTime, 'm');
          offSetDisplay = _timeDiff * _hashMarks;
          boundSessionToViewport();
          setColor(scope.session);
        }

        window.addEventListener('resize', function () {
          tableBodyWidth = null;
          columnLabelWidth = null;
          setAttribs();
        });

        //Due to the action grid getting off-screen for session overlapping days,
        //we force the session to display in the viewport with this function
        function boundSessionToViewport() {
          var diff = 0;

          if (offSetDisplay < 0) {
            //If the timebar offset is smaller than zero, we chop the negative offset from the width
            //and place it to zero (midnight on the calendar)
            diff = sessionWidth + offSetDisplay;
            offSetDisplay = 0;
            sessionWidth = diff;
          } else if (offSetDisplay + sessionWidth > rowBodyWidth) {
            //If the offset + width is bigger than the width of the timebar, we chop off the part after midnight
            //from the width and render just the part that fits on the calendar
            diff = rowBodyWidth - offSetDisplay - sessionWidth;
            sessionWidth += diff;
          }
        }

        //need to get starting range either day or week
        function setMinuteHashMark() {
          if (scope.display == 'week') {
            offsetStartTime = moment(scope.startDate, TimeFormatService.format('api')).startOf('week');
            hashMinuteCount = 10080;
          } else {
            offsetStartTime = moment(scope.startDate, TimeFormatService.format('api')).startOf('day');
            hashMinuteCount = 1440;
          }
          setAttribs();
        }

        function getColor(stateName) {
          if (loadAllJobs) {
            return getColorNew(stateName);
          } else {
            switch (stateName) {
              case 'completed':
                return '#2d3941';
              case 'open':
                return '#FBC53E';
              case 'pending':
                return '#5684AB';
              case 'committed':
                return '#7f5fa4';
              case 'active':
                return '#90C755';
              case 'canceled':
                return '#C74347';
              case 'interest':
                return 'teal';
              case 'waiting decision':
                return 'orange';
            }
          }
        }

        function getColorNew(stateName) {
          switch (stateName) {
            case 'completed':
              return '#0059BD';
            case 'open':
              return '#0070C0';
            case 'pending':
              return '#DC6803';
            case 'committed':
              return '#47CD89';
            case 'active':
              return '#079455';
            case 'canceled':
              return '#BF0000';
            case 'interest':
              return '#B4C5E2';
            case 'waiting decision':
              return '#F79009';
            case 'paused':
              return '#667085';
          }
        }

        function buildOverlapColor(overlapObj) {
          function transparentize(hex, opacity) {
            var bigint = parseInt(hex, 16);
            var r = (bigint >> 16) & 255;
            var g = (bigint >> 8) & 255;
            var b = bigint & 255;

            return r + ',' + g + ',' + b + ',' + opacity;
          }

          var firstColor = getColor(overlapObj.firstSessionState.name.toLowerCase()),
            secondColor = getColor(overlapObj.secondSessionState.name.toLowerCase());

          if (firstColor === secondColor) {
            secondColor = 'rgba(' + transparentize(firstColor, 0.5) + ')';
          }

          return (
            'repeating-linear-gradient(' +
            '0deg,' +
            firstColor +
            ', ' +
            firstColor +
            ' 5px,' +
            secondColor +
            ' 5px,' +
            secondColor +
            ' 10px' +
            ')'
          );
        }

        if (scope.session.state.name.toLowerCase() === 'completed') {
          element.bind('click', function () {
            sessionManagerService.getVerifySession(scope.session.session_id).then(function (result) {
              var objs = timeService.buildCheckInOutObjs(result);
              scope.session.checkIn = objs.checkInObj.localDateTime;
              scope.session.checkOut = objs.checkOutObj.localDateTime;

              const tempStart = moment.utc(`${result.request_date} ${result.request_time}`).local();
              scope.session.start = tempStart.format(TimeFormatService.format('fullDateTimeSecs'));
              scope.session.end = tempStart.add(result.duration, 'm').format(TimeFormatService.format('fullDateTimeSecs'));
            });
          });
        }

        //Logic for waiting until all calculations are done
        //and render every timebar in the same time
        function shouldRender() {
          if (timeout) {
            clearTimeout(timeout);
          }
          timeout = setTimeout(render, 100);
        }

        function render() {
          for (var i = 0; i < toRender.length; i++) {
            toRender[i]();
          }
          toRender = [];
        }

        shouldRender();
      }, //link
    };
  },
]);
