import module from './module';
import moment from 'moment';
import angular from 'angular';

export default module.directive('dirServiceRequestForm', function () {
  function alphabetical(a, b) {
    const A = a.name.toLowerCase();
    const B = b.name.toLowerCase();
    if (A < B) {
      return -1;
    } else if (A > B) {
      return 1;
    } else {
      return 0;
    }
  }
  return {
    restrict: 'E',
    scope: {
      field: '=',
      ent: '=',
      type: '=',
      session: '=',
      index: '=',
    },
    controller: [
      '$scope',
      '$rootScope',
      '$timeout',
      'locationManagerService',
      'DOMAIN',
      'FormatHelperService',
      'timeZoneStore',
      'googleLocationService',
      'TimeFormatService',
      'LocationProvider',
      function (
        $scope,
        $rootScope,
        $timeout,
        locationManagerService,
        DOMAIN,
        FormatHelperService,
        timeZoneStore,
        googleLocationService,
        TimeFormatService,
        LocationProvider
      ) {
        // Image functions for converting
        const imageLoader = (file) => {
          if (file.type.indexOf('image') !== -1) {
            if (FileReader) {
              const _canvasId = $scope.field.name + '_' + $scope.index;
              let canvas = document.getElementById(_canvasId);
              if (!canvas) {
                canvas = document.createElement('canvas');
              }
              const context = canvas.getContext('2d');

              // load default image
              if (file.default_text) {
                // Draw image to canvas in order to convert to jpeg and resize
                const imageObj = new Image();
                imageObj.onload = function () {
                  canvas.width = 856;
                  canvas.height = 1147;
                  context.drawImage(imageObj, 0, 0, 856, 1147);
                  $scope.field.jpeg = canvas.toDataURL('image/png', 0.75).replace(/^data:image\/(png);base64,/, '');
                  $scope.imageUrl = canvas.toDataURL();
                  $scope.showRemoveBtn = true;
                  $scope.$apply();
                };

                const request = new XMLHttpRequest();
                request.open('GET', file.default_text, true);
                request.responseType = 'blob';
                request.onload = function () {
                  const reader = new FileReader();
                  reader.onload = function (e) {
                    imageObj.src = e.target.result;
                  };
                  reader.readAsDataURL(request.response);
                };
                request.send();
              } else {
                // load image by user
                // Draw image to canvas in order to convert to jpeg and resize
                const imageObj = new Image();
                imageObj.onload = function () {
                  canvas.width = 856;
                  canvas.height = 1147;
                  context.drawImage(imageObj, 0, 0, 856, 1147);
                  $scope.field.jpeg = canvas.toDataURL('image/jpeg', 0.75).replace(/^data:image\/(jpeg);base64,/, '');
                  $scope.imageUrl = canvas.toDataURL();
                  $scope.$apply();
                };

                // File Reader to set image obj
                const fr = new FileReader();
                fr.onload = function () {
                  imageObj.src = fr.result;
                };
                fr.readAsDataURL(file);
              }
            } // end if FileReader Present

            $scope.showRemoveBtn = true;
            delete $scope.imageError;
            delete $scope.imageUrl;
          } else {
            $scope.removeFile();
            $scope.imageError = 'Only images are allowed';
          }
        };

        $scope.onImageChangeHandler = function (event, file) {
          imageLoader(file[0]);
        };

        $scope.removeFile = function (e) {
          e && e.preventDefault();
          const _name = $scope.field.name + '_' + $scope.index.toString();
          $('input[name=' + _name + ']').val(null);
          delete $scope.field.value;
          if ($scope.field.jpeg) {
            delete $scope.field.jpeg;
          }
          delete $scope.imageUrl;
          $scope.showRemoveBtn = false;
        };
        // instance count attrib functions
        $scope.addAdditionalAttrib = function (e) {
          e && e.preventDefault();
          $scope.$emit('addAttribInstanceClicked', $scope.field, $scope.index);
        };

        $scope.removeAdditionalAttrib = function (e) {
          e && e.preventDefault();
          $scope.removeFile();
          $scope.$emit('removeAttribInstanceClicked', $scope.field, $scope.index);
        };

        $scope.addInstanceBtnDisplay = function () {
          const _count = $scope.field.instanceCountIndex || 1;
          if (_count === $scope.field.instance_count || $scope.field.doNotShowAddInstance) {
            return false;
          } else {
            return true;
          }
        };
        $scope.removeInstanceBtnDisplay = function () {
          if ($scope.field.showRemoveInstance && $scope.field.instanceCountIndex > 1) {
            return true;
          }
        };

        $scope.multipleInstances = function () {
          if ($scope.field.instance_count && $scope.field.instance_count != 1) {
            return true;
          }
        };
        // Service request switch statements for fields
        function newServiceRequest() {
          switch ($scope.field.type) {
            case 'single_list':
              if ($scope.field.choice_list) {
                const selected = $scope.field.choice_list.filter(function (item) {
                  return item.selected;
                });
                if (selected) {
                  $scope.field.value = selected[0];
                }
              }
              break;
            case 'multi_list':
              break;
            case 'image':
              if ($scope.field && $scope.field.value) {
                // updateImage($scope.field.value);
                $scope.imageUrl =
                  $scope.field.value.indexOf(DOMAIN) === -1 ? DOMAIN + $scope.field.value : $scope.field.value;
                $scope.showRemoveBtn = true;
              } else if ($scope.field && $scope.field.default_text) {
                const field = $scope.field;
                field.default_text =
                  field.default_text.indexOf(DOMAIN) === -1 ? DOMAIN + field.default_text : field.default_text;
                imageLoader(field);
              }
              break;
            case 'location':
              const selectedLocation = locationManagerService.getSelectedLocation();

              $scope.$watch('field.value', function (newVal, oldVal) {
                if (newVal !== oldVal) {
                  locationManagerService.setLocation($scope.field.value);
                  $rootScope.$emit('locationChanged', $scope.field.value);
                }
              });

              $scope.$watch('field.value.location', function (newVal, oldVal) {
                if (newVal !== oldVal) {
                  $rootScope.$emit('locationChanged', $scope.field.value.location);
                }
              });

              locationManagerService.getAllLocations($scope.ent.enterprise_id).then(function (locationsObject) {
                if (locationsObject && $scope.ent.enterprise_id != -1) {
                  const locations = locationsObject[$scope.ent.enterprise_id];
                  $scope.locations = LocationProvider.handleOtherLocation(locations);
                  $scope.locations.sort(alphabetical);
                  if (selectedLocation && selectedLocation.name && selectedLocation.location_id) {
                    $scope.field.value = $scope.locations.filter(function (obj) {
                      return obj.location_id == selectedLocation.location_id;
                    })[0];
                  } else if (selectedLocation && selectedLocation.name && !selectedLocation.location_id) {
                    $scope.locations.push(selectedLocation);
                    $scope.field.value = selectedLocation;
                  }
                } else {
                  $scope.locations = LocationProvider.handleOtherLocation([]);
                  $scope.field.value = $scope.locations[0];
                }
              });
              break;
            case 'place':
              const requiredType = $scope.field.type_flags.required;
              $scope.$watch(function () {
                $scope.location = locationManagerService.getSelectedLocation();
                if ($scope.location) {
                  $scope.checkPlace();
                }
              });

              // if user selects other then hide place. If unselects then reshow
              $scope.checkPlace = function () {
                if ($scope.location.name === 'Other') {
                  $scope.field.type = null;
                  $scope.field.type_flags.required = false;
                } else {
                  $scope.field.type = 'place';
                  $scope.field.type_flags.required = requiredType;
                }
              };
              break;
            case 'text':
              if ($scope.field.max_length < 100) {
                $scope.rows = 1;
              } else {
                $scope.rows = 4;
              }
              // if approving
              if ($scope.field.value_text) {
                $scope.field.value = $scope.field.value_text;
              } else if ($scope.field.default_text && !$scope.field.value) {
                $scope.field.value = $scope.field.default_text;
              }
              break;
            case 'decimal':
              if ($scope.field.value_decimal) {
                $scope.field.value = $scope.field.value_decimal;
              } else {
                $scope.field.value = $scope.field.default_decimal || 0;
              }
              break;
            case 'count':
              $scope.translate = function (value) {
                return FormatHelperService.generateDurationLabel(value);
              };
              if ($scope.field.name === 'max_eta') {
                $scope.field.value = $scope.field.default_count || 0;
                $scope.sliderModel = {
                  options: {
                    floor: $scope.field.min_amount,
                    ceil: $scope.field.max_amount,
                    step: $scope.field.inc_amount,
                    showTicks: false,
                    translate: function (value) {
                      return FormatHelperService.generateDurationLabel(value);
                    },
                  },
                };
                // if approving
                if ($scope.field.value_count) {
                  $scope.field.value = $scope.field.value_count;
                }
              } else if ($scope.field.name === 'duration') {
                $scope.field.value = $scope.field.default_count || 0;
                $scope.sliderModel = {
                  options: {
                    floor: $scope.field.min_amount,
                    ceil: $scope.field.max_amount,
                    step: $scope.field.inc_amount,
                    showTicks: false,
                    translate: function (value) {
                      return FormatHelperService.generateDurationLabel(value);
                    },
                  },
                };
                // if approving
                if ($scope.field.value_count) {
                  $scope.field.value = $scope.field.value_count;
                }
              } else if ($scope.field.name === 'scheduled_wait_time') {
                $scope.field.value = $scope.field.default_count || 0;

                $scope.sliderModel = {
                  options: {
                    floor: $scope.field.min_amount,
                    ceil: 1440,
                    step: 15,
                    showTicks: false,
                    translate: function (value) {
                      return FormatHelperService.generateDurationLabel(value);
                    },
                  },
                };
                // if approving
                if ($scope.field.value_count) {
                  $scope.field.value = $scope.field.value_count;
                }
              } else if ($scope.field.name === 'on_demand_wait_time') {
                $scope.field.value = $scope.field.default_count || 0;

                $scope.sliderModel = {
                  options: {
                    floor: $scope.field.min_amount,
                    ceil: 180,
                    step: 5,
                    showTicks: false,
                    translate: function (value) {
                      return FormatHelperService.generateDurationLabel(value);
                    },
                  },
                };
                // if approving
                if ($scope.field.value_count) {
                  $scope.field.value = $scope.field.value_count;
                }
              } else {
                $scope.translate = function (value) {
                  return value + ' ';
                };
                $scope.field.value = $scope.field.default_count || 0;
                $scope.sliderModel = {
                  options: {
                    floor: $scope.field.min_amount,
                    ceil: $scope.field.max_amount,
                    step: Math.min($scope.field.inc_amount, 100),
                    showTicks: false,
                    translate: function (value) {
                      return FormatHelperService.generateDurationLabel(value);
                    },
                  },
                };
                // if approving
                if ($scope.field.value_count) {
                  $scope.field.value = $scope.field.value_count;
                }
              }
              break;
            case 'date_time':
              $scope.status = {
                opened: false,
              };
              $scope.open = function ($event) {
                $scope.status.opened = !$scope.status.opened;
              };
              $scope.clearDateTime = function () {
                $scope.field.value = null;
                $scope.open();
              };
              // Highlight current time
              $scope.beforeRender = function ($view, $dates) {
                const todayDate = moment().startOf($view).valueOf();
                angular.forEach($dates, function (date) {
                  const localDateValue = date.localDateValue();
                  date.active = localDateValue == todayDate;
                });
              };
              // if approval
              if ($scope.field.value_text) {
                // Takes UTC date and parses to local
                const date = moment.utc($scope.field.value_text, 'MMDDYYYY HH:mm').local();
                $scope.field.value = date;
              }
              break;
            case 'date':
              $scope.dateField = { opened: false };
              $scope.openDateField = function () {
                $scope.dateField.opened = true;
              };
              break;
            case 'flag':
              $scope.field.value = $scope.field.value || $scope.field.default_flag;
              break;
          } // end switch
        } // end newServiceRequest schedule and now forms fields

        function serviceApproval(isResend) {
          $scope.type = 'edit';
          if (!$scope.field.type_flags) {
            $scope.field.type_flags = {
              required: $scope.field.required,
              display: $scope.field.display,
              read_only: $scope.field.read_only,
            };
          }

          switch ($scope.field.type) {
            case 'single_list':
              if ($scope.field.value_list) {
                $scope.field.choice_list = $scope.field.value_list;
                $scope.field.value = $scope.field.value_list.filter(function (obj) {
                  return obj.selected == true;
                })[0];
              }
              break;
            case 'multi_list':
              if ($scope.field.value_list) {
                $scope.field.value = $scope.field.value_list;
                $scope.field.choice_list = $scope.field.value_list;
              }
              break;
            case 'image':
              if ($scope.field.data) {
                $scope.imageUrl = DOMAIN + $scope.field.data;
              } else if ($scope.field.value_text) {
                $scope.imageUrl = DOMAIN + $scope.field.value_text;
              }
              break;
            case 'location':
              $scope.$watch('field.value', function (newVal, oldVal) {
                if (newVal !== oldVal) {
                  locationManagerService.setLocation($scope.field.value);
                  $rootScope.$emit('locationChanged', $scope.field.value);
                }
              });

              locationManagerService.getAllLocations($scope.ent.enterprise_id).then(function (locationsObject) {
                if (locationsObject && $scope.ent.enterprise_id != -1) {
                  const locations = locationsObject[$scope.ent.enterprise_id];
                  if (angular.isArray($scope.field.value_location)) {
                    $scope.locations = LocationProvider.handleOtherLocation($scope.field.value_location);
                    $scope.locations.sort(alphabetical);
                    $scope.field.value = $scope.locations.filter(function (obj) {
                      return obj.selected == true;
                    })[0];
                  } else {
                    $scope.locations = LocationProvider.handleOtherLocation(locations);
                    $scope.locations.sort(alphabetical);
                    if ($scope.field.value_location.location_id) {
                      // selected location exists in DB
                      $scope.field.value = $scope.locations.filter(function (obj) {
                        return obj.location_id == $scope.field.value_location.location_id;
                      })[0];
                    } else {
                      // other location was selected
                      $scope.locations.push($scope.field.value_location);
                      $scope.field.value = $scope.locations.filter(function (obj) {
                        return obj.selected == true;
                      })[0];
                    }
                  }
                } else {
                  $scope.locations = LocationProvider.handleOtherLocation([]);
                  $scope.field.value = $scope.locations[0];
                }
                $scope.$emit('LocationsLoaded');
              });
              break;
            case 'place':
              $scope.$watch(function () {
                $scope.location = locationManagerService.getSelectedLocation();
                // checks to see if selected location is in set list
                if ($scope.location && $scope.location.location_id) {
                  $scope.field.type = 'place';
                  $scope.getPlaces($scope.location.location_id);
                } else {
                  $scope.field.type = null;
                }
              });

              // assign selected place for display
              $timeout(function () {
                if ($scope.field.type && $scope.field.value_place) {
                  var selected = $scope.field.value_place.filter(function (obj) {
                    return obj.selected == true;
                  })[0];
                  $scope.field.value = $scope.field.value_place.filter(function (obj) {
                    return obj.name == selected.name;
                  })[1];
                }
              }, 200);

              // get list of places for selected location
              $scope.getPlaces = function (loc_id) {
                if ($scope.field.value_place) {
                  $scope.location.place_list = $scope.field.value_place.filter(function (obj) {
                    return obj.location_id == loc_id;
                  });
                }
              };
              break;
            case 'text':
              if ($scope.field.max_length < 100) {
                $scope.rows = 1;
              } else {
                $scope.rows = 4;
              }

              $scope.field.value_text ? ($scope.field.value = $scope.field.value_text) : null;
              break;
            case 'decimal':
              $scope.field.value_decimal ? ($scope.field.value = $scope.field.value_decimal) : null;
              break;
            
            case 'count':
              $scope.field.value_count ? ($scope.field.value = $scope.field.value_count || 0) : null;

              if ($scope.field.name == 'max_eta') {
                $scope.sliderModel = {
                  options: {
                    floor: $scope.field.min_amount,
                    ceil: $scope.field.max_amount,
                    step: $scope.field.inc_amount,
                    showTicks: false,
                    translate: function (value) {
                      return FormatHelperService.generateDurationLabel(value);
                    },
                  },
                };
              } else if ($scope.field.name == 'duration') {
                $scope.sliderModel = {
                  options: {
                    floor: $scope.field.min_amount || 0,
                    ceil: $scope.field.max_amount || 1400,
                    step: $scope.field.inc_amount || 15,
                    showTicks: false,
                    translate: function (value) {
                      return FormatHelperService.generateDurationLabel(value);
                    },
                  },
                };
              } else if ($scope.field.name == 'scheduled_wait_time') {
                $scope.sliderModel = {
                  options: {
                    floor: $scope.field.min_amount,
                    ceil: 1440,
                    step: 15,
                    showTicks: false,
                    translate: function (value) {
                      return FormatHelperService.generateDurationLabel(value);
                    },
                  },
                };
              }
              break;
            case 'date_time':
              //Takes UTC date and parses to local
              if ($scope.field.value_text) {
                const utcTime = moment.utc($scope.field.value_text, 'MMDDYYYY HH:mm')
                if (isResend){
                  const localTime = utcTime.clone().local();
                  const timestamp = new Date(localTime).getTime() / 1000;
                  googleLocationService.getTimezone($scope.session.location.value_location, timestamp).then((data) => {
                    if (data && data !== null) {
                        timeZoneStore.load(data);
                        $scope.field.value = utcTime.clone().tz(data.timeZoneId);
                        $scope.field.value_text = utcTime.clone().format(TimeFormatService.format('fullDateHourMinute'));
                      }
                  });
                }
                else{
                  $scope.field.value = utcTime.local();
                }
              }

              $scope.status = {
                opened: false,
              };
              $scope.open = function ($event) {
                $scope.status.opened = !$scope.status.opened;
              };
              $scope.clearDateTime = function () {
                $scope.field.value = null;
                $$cope.open();
              };
              break;
            case 'date':
              if ($scope.field.value_text) {
                var date = moment($scope.field.value_text, TimeFormatService.format('fullDateNoSeparator')).clone();
                $scope.field.value = date;
              }
              //For contorlling opening and closing of date picker
              $scope.dateField = { opened: false };
              $scope.openDateField = function () {
                $scope.dateField.opened = true;
              };
              break;
            case 'flag':
              if ($scope.field.data) {
                $scope.field.value = $scope.field.data;
              } else if ($scope.field.value_flag) {
                $scope.field.value = $scope.field.value_flag;
              }
              break;
          } //end switch
        } // end serviceAprroval function

        ////////////////////////////////////////////////////////////////////////////////
        //Transfer split
        function transferSplitService(isResend) {
          var filledInAttrib = selectedAttrib($scope.field.name);

          switch ($scope.field.type) {
            case 'single_list':
              if (filledInAttrib) {
                $scope.field.choice_list = filledInAttrib.value_list;
                $scope.field.value = filledInAttrib.value_list.filter(function (obj) {
                  return obj.selected == true;
                })[0];
              }
              break;
            case 'multi_list':
              if (filledInAttrib) {
                $scope.field.value = filledInAttrib.value_list;
                $scope.field.choice_list = filledInAttrib.value_list;
              }
              break;
            case 'image':
              // User has to reupload if they want same photos
              break;
            case 'location':
              if (filledInAttrib) {
                const locId = filledInAttrib.value_location.location_id;
                locationManagerService.getLocation(locId).then(function (response) {
                  locationManagerService.setLocation(response);
                  $scope.field.value = response;
                });
              }
              $scope.$watch('field.value', function () {
                if ($scope.field.value) {
                  locationManagerService.setLocation($scope.field.value);
                  $rootScope.$emit('locationChanged', $scope.field.value);
                }
              });
              $scope.$watch('field.value.location', function () {
                if ($scope.field.value && $scope.field.value.location) {
                  $rootScope.$emit('locationChanged', $scope.field.value.location);
                }
              });

              locationManagerService.getAllLocations($scope.ent.enterprise_id).then(function (locationsObject) {
                if (locationsObject && $scope.ent.enterprise_id != -1) {
                  var locations = locationsObject[$scope.ent.enterprise_id];
                  $scope.locations = LocationProvider.handleOtherLocation(locations);
                  $scope.locations.sort(alphabetical);
                } else {
                  $scope.locations = LocationProvider.handleOtherLocation([]);
                  $scope.field.value = $scope.locations[0];
                }
                $scope.$emit('LocationsLoaded');
              });
              break;
            case 'place':
              $scope.$watch(function () {
                $scope.location = locationManagerService.getSelectedLocation();
                // checks to see if selected location is in set list
                if ($scope.location && $scope.location.location_id) {
                  $scope.field.type = 'place';
                  $scope.getPlaces($scope.location.location_id);
                } else {
                  $scope.field.type = null;
                }
              });

              //assign selected place for display
              if (filledInAttrib) {
                $timeout(function () {
                  if ($scope.field.type) {
                    var selected = filledInAttrib.value_place.filter(function (obj) {
                      return obj.selected == true;
                    })[0];
                    $scope.field.value = filledInAttrib.value_place.filter(function (obj) {
                      return obj.name == selected.name;
                    })[0];
                  }
                }, 500);
              }

              // get list of places for selected location
              $scope.getPlaces = function (loc_id) {
                if (filledInAttrib) {
                  $scope.location.place_list = filledInAttrib.value_place.filter(function (obj) {
                    return obj.location_id == loc_id;
                  });
                }
              };

              // if user selects other then hide place. If unselects then reshow
              $scope.checkPlace = function () {
                if ($scope.location.name == 'Other') {
                  $scope.field.type = null;
                  $scope.field.type_flags.required = false;
                } else {
                  $scope.field.type = 'place';
                  $scope.field.type_flags.required = requiredType;
                }
              };
              break;
            case 'text':
              if ($scope.field.max_length < 100) {
                $scope.rows = 1;
              } else {
                $scope.rows = 4;
              }

              filledInAttrib ? ($scope.field.value = filledInAttrib.value_text) : null;
              break;
            case 'decimal':
              $scope.field.value_decimal ? ($scope.field.value = $scope.field.value_decimal) : null;
              break;
            case 'count':
              filledInAttrib ? ($scope.field.value = filledInAttrib.value_count || 0) : null;

              if ($scope.field.name == 'max_eta') {
                $scope.sliderModel = {
                  options: {
                    floor: $scope.field.min_amount,
                    ceil: $scope.field.max_amount,
                    step: $scope.field.inc_amount,
                    showTicks: false,
                    translate: function (value) {
                      return FormatHelperService.generateDurationLabel(value);
                    },
                  },
                };
              } else if ($scope.field.name == 'duration') {
                $scope.sliderModel = {
                  options: {
                    floor: $scope.field.min_amount || 0,
                    ceil: $scope.field.max_amount || 1400,
                    step: $scope.field.inc_amount || 15,
                    showTicks: false,
                    translate: function (value) {
                      return FormatHelperService.generateDurationLabel(value);
                    },
                  },
                };
              } else if ($scope.field.name == 'scheduled_wait_time') {
                $scope.sliderModel = {
                  options: {
                    floor: $scope.field.min_amount,
                    ceil: 1440,
                    step: 15,
                    showTicks: false,
                    translate: function (value) {
                      return FormatHelperService.generateDurationLabel(value);
                    },
                  },
                };
              }
              break;
            case 'date_time':
              if (isResend) {
                if ($scope.field.value_text) {
                  var date = moment.utc($scope.field.value_text, 'MMDDYYYY HH:mm').local();
                  $scope.field.value = date;
                }
              } else {
                $scope.$watch('session.new_end', function () {
                  var date = moment($scope.session.new_end).clone();
                  $scope.field.value = date;
                });
              }
              $scope.status = {
                opened: false,
              };
              $scope.open = function ($event) {
                $scope.status.opened = !$scope.status.opened;
              };
              break;
            case 'date':
              if (filledInAttrib && filledInAttrib.value_text) {
                var date = moment(filledInAttrib.value_text, TimeFormatService.format('fullDateNoSeparator')).clone();
                $scope.field.value = date;
              }
              //For contorlling opening and closing of date picker
              $scope.dateField = { opened: false };
              $scope.openDateField = function () {
                $scope.dateField.opened = true;
              };
              break;
            case 'flag':
              filledInAttrib ? ($scope.field.value = filledInAttrib.value_flag || filledInAttrib.data) : null;
              break;
          } //end switch
        } // end transfer split function
        ////////////////////////////////////////////////////////////////////////////////
        // switch case based on field type
        if ($scope.type === 'approval' || $scope.type === 'resend') {
          serviceApproval($scope.type === 'resend');
        } else if ($scope.type == 'transfer split') {
          transferSplitService();
        } else {
          newServiceRequest();
        }

        function selectedAttrib(attribName) {
          if ($scope.session) {
            var attrib = $scope.session.attrib_list.filter(function (obj) {
              return obj.name == attribName;
            })[0];
            if (attrib && attrib.type !== 'image') {
              return attrib;
            }
          }
        }
      },
    ],
    templateUrl: 'app/views/templates/service_request_fields.html',
  };
});
