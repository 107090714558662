import { Injectable } from '@angular/core';
import { SecureHttpService } from '../secure.http.service';
import Env from '../../../scripts/env.js';
import { catchError, map } from 'rxjs/operators';
import { firstValueFrom, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminUserService {
  private endpointRoot = `${Env.ADMIN_API}/admin-users/`;
  constructor(private http: SecureHttpService) { }

  public getAllAdminUsers() {
    return this.http.get(this.endpointRoot).toPromise();
  }

  public getAdminUserDetails(id) {
    return this.http.get(`${this.endpointRoot}/${id}/`).toPromise();
  }

  public editUser(data) {
    return this.http.patch(`${this.endpointRoot}/${data.id}/`, data).toPromise();
  }

  public addNewUser(data) {
    return this.http.post(this.endpointRoot, data).toPromise();
  }

  public removeUser(id) {
    return this.http.delete(`${this.endpointRoot}/${id}/`).toPromise();
  }

  public referAdmin(data) {
    return this.http.post(`${this.endpointRoot}/refer_admin/`, data).toPromise();
  }

  public signup(data) {
    return this.http
      .post(`${this.endpointRoot}/signup/`, data)
      .pipe(
        map((res) => {
          if (res.username) {
            return {
              message: 'Successfully created new user!',
            };
          } else {
            return throwError({});
          }
        }),
        catchError((err) => {
          if (err?.detail?.indexOf('Not found') > -1) {
            return throwError('Invite not found');
          } else if (err?.username) {
            return throwError('The username is already in use');
          } else {
            return throwError('There was a problem with the invite');
          }
        })
      )

      .toPromise();
  }

  public sendForgotPasswordRequest(username: string, marketplaceId: number, webUrl: string): Promise<any> {
    return firstValueFrom(
      this.http.postNoToken(this.endpointRoot + 'forgot_password/send_email/', {
        username,
        web_url: webUrl,
        market_place_id: marketplaceId,
      })
    );
  }

  public resetPassword(payload: {
    password: string;
    token: string;
    username: string;
    marketplaceId: number;
  }): Promise<any> {
    return firstValueFrom(this.http.postNoToken(this.endpointRoot + 'forgot_password/reset/', payload));
  }

  public resetExpiredPassword(payload: {
    old_password: string;
    new_password: string;
  }): Promise<any> {
    return firstValueFrom(this.http.post(this.endpointRoot + '/admin_change_password/', payload));
  }
}
